import type {LoaderFunction, LinksFunction} from '@remix-run/server-runtime';
import {useLoaderData} from '@remix-run/react';
import {json} from '@remix-run/cloudflare';
import i18n from 'i18next';

import {loadRequestData} from '@/utils/server/request.server';
import PageLayout from '@/components/shared/Page/PageLayout';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import Header from '@/components/brochureV2/section/Header/Header';
import {HeaderBackgroundColorScheme, ColorSchemeEnum} from '@/enums';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {camelize} from '@/utils/utils';
import {Page} from '@/utils/server/Page';
import {addFormattersForSite} from '@/i18n';
import {useSiteData} from '@/hooks/useSiteData';
import LegalArticle from '@/pages/shopify.com/($locale)/legal/components/LegalArticle/LegalArticle';

import customStyleSheet from '../styles/stylesheet.css?url';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: customStyleSheet}];
};

export const loader: LoaderFunction = async (args) => {
  const {storage} = args.context;
  const requestData = await loadRequestData(args);
  const {site, enPath} = requestData;
  const enPathSplit = enPath?.split('/') ?? [];
  const article = enPathSplit?.[enPathSplit.length - 1] ?? '';
  const fallbackPath = `locales/shopify.com/en/pages/legal/$article/${article}.md`;
  const langExceptions: string[] = ['pt-BR', 'zh-CN'];

  const language = langExceptions.includes(site?.locale)
    ? site?.locale
    : site?.locale.split('-')[0] || 'en';
  const hasSubDirectory =
    language === 'en' ? enPathSplit?.length > 2 : enPathSplit?.length > 3;
  const localizedContentMd = hasSubDirectory
    ? await storage.getKey(
        `locales/shopify.com/${language}/pages/legal/$article/${enPathSplit
          ?.slice(2, -1)
          .join('/')}/${article}.md`,
      )
    : await storage.getKey(
        `locales/shopify.com/${language}/pages/legal/$article/${article}.md`,
      );
  const contentMd = localizedContentMd
    ? localizedContentMd
    : await storage.getKey(fallbackPath);
  const page = enPath ? Page.byPath(enPath) : undefined;
  const pageIsPublished = page?.isPublishedOnSite(site);

  if (!contentMd || !pageIsPublished) {
    throw new Response('Not found', {status: 404});
  }

  return json({
    contentMd,
    ...requestData,
    article,
    enPath,
  });
};

export default function LegalArticlePage() {
  const {
    contentMd = '',
    article = '',
    enPath = '',
  }: {contentMd: string; article: string; enPath: string} = useLoaderData();
  const {t: tContent} = useSharedTranslations('pages/legal/$article/content');
  const t = (key: string) => tContent(`${camelize(article)}.${key}`);
  const {values} = useTranslations();
  const {site} = useSiteData();
  i18n.init();
  addFormattersForSite(i18n, site);
  const parsedContentMd = String(i18n.t(contentMd, values));
  const isPrivacyPolicy = enPath?.includes('privacy');
  const privacyLinks = [
    '/legal/privacy',
    '/legal/privacy/merchants',
    '/legal/privacy/app-users',
    '/legal/privacy/app-users' /* Intentionally repeated because Customers nav link is still important but redirects to this page */,
    '/legal/privacy/partners',
    '/legal/privacy/visitors',
  ];

  return (
    <PageLayout
      header={
        isPrivacyPolicy
          ? (props) => (
              <Header
                {...props}
                secondaryNavFn={({localizePath}) => ({
                  sectionTitle: tContent('privacy.hero.headingHtml'),
                  pageTitle: t('htmlHead.pageTitle'),
                  sectionOverviewUrl: localizePath('/legal/privacy'),
                  links: privacyLinks.map((link, index) => ({
                    text: tContent('privacy.subNav.links')[index],
                    url: localizePath(link),
                  })),
                })}
                background={HeaderBackgroundColorScheme.TransparentDark}
              />
            )
          : (props) => (
              <Header
                {...props}
                background={HeaderBackgroundColorScheme.TransparentDark}
              />
            )
      }
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
      title={
        isPrivacyPolicy
          ? tContent('privacy.htmlHead.pageTitle')
          : t('htmlHead.pageTitle')
      }
      metaDescription={
        isPrivacyPolicy
          ? tContent('privacy.htmlHead.metaDescripton')
          : t('htmlHead.metaDescription') ||
            tContent('shared.htmlHead.metaDescription')
      }
    >
      <Section mode="dark">
        <SectionHeader
          mode="dark"
          headingAs="h1"
          size="t1"
          center
          className={isPrivacyPolicy && 'pt-hero-top'}
          headingHtml={t('htmlHead.pageTitle')}
          {...t('hero')}
        />
      </Section>
      <Section>
        <LegalArticle parsedContentMd={parsedContentMd} />
      </Section>
    </PageLayout>
  );
}
